<template>
  <div id="appCapsule">
    <div class="section wallet-card-section">
      <div class="card">
        <div class="col-12 ps-2 text-primary py-2">
          All Users
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Currency</th>
              <th scope="col">Type</th>
              <th scope="col">Date created</th>
              <th scope="col" class="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="getAllUsers.length > 0">
              <tr v-for="(user, idx) in getAllUsers" :key="idx">
                <th scope="row">{{idx+1}}</th>
                <td>{{user.data.name}}</td>
                <td>{{user.data.email}}</td>
                <td>{{user.data.phone}}</td>
                <td>{{user.data.currency}}</td>
                <td>
                                    <span class="badge badge-primary" v-if="user.data.is_admin">
                                        admin
                                    </span>
                  <span class="badge badge-info" v-else>
                                        user
                                    </span>
                </td>
                <td>{{getReadableDatetime(user.data.created_at)}}</td>
                <td>
                  <div class="row justify-content-around">
                    <div class="dropdown">
                      <a id="usr-drop" class="dropdown-toggle fw-20" data-bs-toggle="dropdown" aria-expanded="false">
                        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                      </a>
                      <div class="dropdown-menu" style="">
                        <a class="dropdown-item" href="javascript:void(0)" @click="active_payload=user" data-bs-toggle="modal" data-bs-target="#viewUserInfo">
                          <ion-icon class="text-primary fw-20" name="eye-outline"></ion-icon> view
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="active_payload=user" data-bs-toggle="modal" data-bs-target="#fundWallet">
                          <ion-icon name="wallet-outline" class="text-info fw-20"></ion-icon> fund wallet
                        </a>
                        <router-link :to="{name:'UserSupportMessages', params: {userId: user.id}}" class="dropdown-item text-success text-center">
                          <ion-icon name="chatbubble-ellipses-outline" class="fw-20"></ion-icon> chat
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)">
                          <ion-icon name="close-outline" role="img" class="md hydrated" aria-label="close outline"></ion-icon>
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="text-center text-primary" colspan="8">
                  <h5>Empty user history</h5>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <view-user-info :user="active_payload"/>
    <fund-user-wallet :user="active_payload"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import methodsMixin from "../../../utils/methodsMixin";
import viewUserInfo from "../../../components/admin/modals/viewUserInfo";
import fundUserWallet from "../../../components/admin/modals/fundUserWallet";

export default {
  name: "users",
  data(){
    return {
      active_payload: {id: '', data: {}}
    }
  },
  mixins: [methodsMixin],
  computed: {
    ...mapGetters('user', [
      'getAllUsers'
    ])
  },
  components: {
    viewUserInfo,
    fundUserWallet
  },
  mounted() {
    this.$store.dispatch('user/fetchAllUsers')
  }
}
</script>

<style scoped>
#usr-drop:after{
  content: none;
}
</style>