<template>
    <div class="modal fade modalbox" id="fundWallet" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Fund {{user.data.name}}'s Wallet
                    </h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="form-label">Select Crypto</label>
                        <select class="form-control" v-model="selected_crypto">
                            <option :value="key" v-for="(value, key, idx) in $globalFunc.cryptoList" :key="idx">
                                {{value}} [{{key}}]
                            </option>
                        </select>
                    </div>
                    <div class="form-group mt-2">
                        <label class="form-label">Amount in {{selected_crypto}}</label>
                        <input type="text" v-model.number="amount" class="form-control" :placeholder="'0.00 '+selected_crypto">
                    </div>
                    <div class="row mt-3">
                        <button class="btn btn-primary" @click="complete_transaction">Fund Wallet</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "fundUserWallet",
    data(){
      return {
          selected_crypto: 'BTC',
          amount: ''
      }
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: ()=>{
                return {id: '', data: {}}
            }
        }
    },
    methods: {
        complete_transaction(){
            if(typeof this.amount === 'number' && this.selected_crypto){
                const {user, amount} = this;
                this.$store.dispatch('notification/display_noti', {
                    message: 'Fund this user\'s wallet? <br> This operation can not be reversed!',
                    context: 'icon',
                    buttons: [
                        {
                            name: 'Fund',
                            callback: async ()=>{
                                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                                this.$loader.show()
                                let response = await this.$store.dispatch('wallet/fundUserWallet', {
                                    user,
                                    amount,
                                    cryptoId: this.selected_crypto
                                });
                                this.$loader.hide()
                                if(response.status){
                                    $('#fundWallet').modal('hide');
                                    this.amount = ''; this.selected_crypto = 'BTC';
                                    return this.$store.dispatch('notification/display_noti', {
                                        message: 'Operation successful!',
                                        timeout: 1500
                                    });
                                }else{
                                    return this.$store.dispatch('notification/display_noti', {
                                        message: response.message,
                                    });
                                }
                            }
                        },
                        {
                            name: 'Cancel',
                            callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                        }
                    ]
                });
            }
        }
    }
}
</script>

<style scoped>

</style>