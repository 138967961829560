<template>
    <div class="modal fade modalbox" id="viewUserInfo" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{user.data.name}}
                        <span class="text-end">
                            <small class="badge badge-primary" v-if="user.data.is_admin">admin</small>
                            <small class="badge badge-info" v-else>user</small>
                        </span>
                    </h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <p class="text text-primary">
                        Balance
                        <span class="float-end">
                                {{usr_balance||0}} {{user.data.currency}}
                            </span>
                    </p>
                    <p class="text text-info">
                        Assets
                    </p>
                    <p class="text text-info">
                        <span v-for="(value, key, idx) in user.data.assets" :key="'asset_'+idx">
                            {{value}} {{key}} <br>
                        </span>
                    </p>
                    <p class="text text-dark">
                        Referral ID
                        <span class="float-end">
                                {{user.id}}
                            </span>
                    </p>
                    <p class="text text-dark">
                        Referred By
                        <span class="float-end">
                                {{user.data.referred_by||'None'}}
                            </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '../../../models/user';

export default {
    name: "viewUserInfo",
    data(){
        return {
            user_profile: {id: '', data: {}},
            usr_balance: 0
        }
    },
    watch: {
        user(newVal){
            this.user_profile = {id: '', data: {}};
            const tmp_usr = new User(newVal.data, newVal.id);
            tmp_usr.assetsToFiat().then(result=>this.usr_balance=result)
            // this.$store
            //     .dispatch('user/getUserProfile', newVal.id)
            //     .then(response=>{
            //         this.user_profile = response.data;
            //     });
        }
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: ()=>{
                return {id: '', data: {}}
            }
        }
    },
    mounted(){
        $('#viewUserInfo').on('hidden.bs.modal', ()=>{
            this.usr_balance = 0;
        })
    }
}
</script>

<style scoped>

</style>